import Glide from "@glidejs/glide";

// Initialize the glide carousel on page load
window.addEventListener("load", function() {
    new Glide(".glide", {
        'type': 'carousel',
        'startAt': 0,
        'perView': 2,
        'bound': true,
        'gap': 30,
        'peek': {
            'before': 0,
            'after': 240
        },
        'breakpoints': {
            1024: {
                'perView': 2,
                'peek': {
                    'before': 0,
                    'after': 145
                }
            },
            925: {
                'perView': 2,
                'peek': {
                    'before': 0,
                    'after': 100
                }
            },
            768: {
                'perView': 1,
                'peek': {
                    'before': 0,
                    'after': 250
                }
            },
            720: {
                'perView': 1,
                'peek': {
                    'before': 0,
                    'after': 180
                }
            },
            625: {
                'perView': 1,
                'peek': {
                    'before': 0,
                    'after': 100
                }
            },
            600: {
                'type': 'slider',
                'perView': 1,
                'gap': 5,
                'peek': {
                    'before': 25,
                    'after': 25
                }
            }

        }
    }).mount();

    const ratingStarsObserver = new IntersectionObserver(intersections => {
        intersections.forEach(({
           target,
           isIntersecting
        }) => {
            document.querySelectorAll('.trusted .ratings .stars-wrapper svg').forEach(el => {
                el.classList.toggle('animate', isIntersecting);
            });
        });
    }, {
        threshold: 0
    });

    document.querySelectorAll('.trusted').forEach(div => {
        ratingStarsObserver.observe(div);
    });

    const data = {
        datasets: [{
            data: [96, 4],
            backgroundColor: [
                '#ACEBFF',
                '#094870',
            ]
        }]
    };

    const config = {
        type: 'doughnut',
        data: data,
        options: {
            radius: 135,
            cutout: '78%',
            borderWidth: 0,
            events: [],
        }
    };

    const ctx = document.getElementById('recommend-chart');

    let chart = new Chart(ctx, config);

    const observer = new IntersectionObserver(intersections => {
        intersections.forEach(({
                                   target,
                                   isIntersecting
                               }) => {
            document.querySelector('.pie-chart_text').classList.toggle('animate', isIntersecting);
            document.querySelector('.pie-chart_title').classList.toggle('animate', isIntersecting);
            document.querySelector('.pie-chart_subtitle').classList.toggle('animate', isIntersecting);
            document.querySelector('.pie-chart_disclaimer').classList.toggle('animate', isIntersecting);
            //render chart after animation
            if (isIntersecting) {
                chart.destroy();
                setTimeout(() => {
                    chart = new Chart(ctx, config);
                }, 1000);
            }
        });
    }, {
        threshold: 0
    });

    document.querySelectorAll('.pie-chart').forEach(div => {
        observer.observe(div);
    });
});